import React, { useState } from 'react';
import './App.css';
import 'rsuite/styles/index.less';
import "rsuite/Container/styles/index.less"
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import appRoutes from 'src/constants/appRoutes';

function App() {

  return (
    <div className="show-fake-browser navbar-page">

      <Router>
        <Routes>
          {
            appRoutes?.map((el) => {
              return (
                <Route path={"/" + el.path} element={el.component} />
              )
            })
          }
        </Routes>
      </Router>

    </div>
  );
}

export default App;
import React from "react"
import Manav from './manav.svg'

const Logo = (props)=>{
    return (
      <img style={props} src={Manav} />

    )
}

export default Logo;
import React from "react";
import "./Hero.css";
import { motion } from 'framer-motion/dist/framer-motion'

import { Grid, IconButton } from '@mui/material';

const Hero = ({ title, subtitle, executeScroll }) => {
  return (
    <div className="hero" style={{ display: 'flex', flexDirection: "column", justifyContent: "center", alignItems: "flex-start", backgroundColor: "#003DEA" }}>
      {
        title ?
          <p
            className="hero-text"
            style={{
              color: 'white',
              lineHeight: 1,
              fontFamily: "Futura"
            }}
          >
            {title}
          </p>
          :
          <p
            className="hero-text"
            style={{
              color: 'white',
              lineHeight: 1,
              fontFamily: "Futura"
            }}>
            Products that increase <br />
            quality of life worldwide.
          </p>
      }

      {
        subtitle &&
        <div className="text-container">
          <Grid container spacing={10}>
            <Grid item md={8}>
              <p
                className="hero-sub-text"
                style={{
                  color: 'white',
                  fontFamily: "Futura"
                }}>
                Manav is an Istanbul based product studio
                that creates apps which help millions of people
                solve daily problems.
              </p>

            </Grid>
            <Grid item md={3}>
              <IconButton onClick={() => executeScroll(1)} style={{ paddingLeft: 0 }}>
                <p
                  className="scroll-down-text"
                  style={{
                    color: 'white',
                    fontFamily: "Futura"
                  }}>
                  Scroll Down
                </p>
                <img src="down-arrow.png" style={{ width: 20, height: 20, marginLeft: 12 }} />
              </IconButton>
            </Grid>
          </Grid>
        </div>
      }
      {/* <img className="hidden" src="heroBadge.png" style={{height: 200, bottom: 200,  transform: 'rotate(5deg)', width: 200, position: "absolute", zIndex: 0, right: -50}}/> */}

    </div>
  )
}
export default Hero;
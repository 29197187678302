import React from "react";
// import './Who.css';

import {Grid, IconButton} from '@mui/material';

const InfoSection = ()=>{
  return( 
    <div className="hero" style={{alignItems: "center",  display:'flex', flexDirection: "column"}}>
      <Grid container spacing={10}>
        
        <Grid className="hidden" item md={5}>
          <img src="infoImg.png" style={{width: "90%"}}/>
        </Grid>

        <Grid style={{display: "flex", flexDirection: "column", justifyContent: "center"}} item sm={12} md={7}>
          <p
            style={{
              fontFamily: "Futura",
              color: "#252525",
              fontSize: 52,
              lineHeight: 1.2
            }}
          >
            App´s designed to enrich our Users life. 
          </p>

          <p
          style={{
            fontFamily: "Futura",
            color: "#252525",
            paddingTop: 20,
            fontSize: 20,
          }}>
            our product designers tasks is to create value for or users in 3 different areas of life,
            health, entertainment and productivity. We know that apps can´t solve all the major problems on earth,
            but it increase the quality of life of millions. 
          </p>
        </Grid>
      </Grid>
    </div>
  )
}

export default InfoSection;
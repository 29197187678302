import React, { forwardRef, useEffect, useRef, useState } from "react";
import Logo from "../../components/Logo";
import './navbar.css'
import { motion } from 'framer-motion/dist/framer-motion'
import CTAButton from "../../components/CTAButton";

const Navbar = ({ executeScroll }) => {

	return (
		<div className="nav" style={{ display: "flex", paddingTop: 30,  backgroundColor: "#003DEA", flexDirection: 'row', display: "flex", justifyContent: 'space-between', alignItems: 'center' }}>
			<div className="links" style={{ display: "flex", alignItems: 'center', justifyContent: 'center', textAlign: 'center' }}>
				<motion.div
					viewport={{ once: true }}
					initial={{ opacity: 0 }}
					whileInView={{ opacity: 1 }}>
				
					<a onClick={() => executeScroll(3)} style={{ color: 'white', marginRight: 20, fontSize: 16, fontWeight: 600, textAlign: 'center' }}>
						<Logo width={90}/>
					</a>
				</motion.div>
			</div>

			<CTAButton
				color="white"
				onClick={()=> {
					window.location.href = "mailto:hello@manav.studio"
				}}
				text={'Apply for a job'}
			/>
		</div>
	)
}
export default Navbar;
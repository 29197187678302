import React from "react"
import "./Footer.css";

const Footer = ()=>{
	return(
    <div className="hero" style={{alignItems: "center", display:'flex', flexDirection: "column"}}>

			<a onClick={()=> {
				window.location.href = "mailto:hello@manav.studio"
			}}>
				<div style={{flexDirection: "row", display: "flex", alignItems: "center"}}>
					<p style={{color: "#003DEA", textAlign: "center", lineHeight: 1.1, fontFamily: "Futura", fontSize: 68}}>
						Apply for jobs
					</p>
					<img src="arrow-action-blue.png" style={{width: 52, height: 52, marginTop: 12}}/>
				</div>
			</a>


      <p style={{color: "#252525", textAlign: "center", fontFamily: "Futura", paddingTop: 40, fontSize: 30}}>
				we aim to built products that increase<br/>the quality of life for millions.
			</p>

			<p style={{color: "#252525", textAlign: "center", fontFamily: "Futura", paddingTop: 20, fontSize: 18}}>
					Beyoğlu 79 <br/>
					9000 İstanbul<br/>
					hello@manav.studio<br/>
					+90 (0)1 234 45 67
			</p>


			<div style={{display: "flex", flexDirection: "row", paddingTop: 24}}>

				{
					[
						{
							name: "IG",
						},
						{
							name: "FB",
						},
						{
							name: "IN",
						},
						{
							name: "TW",
						}
					].map((el)=> {
						return(
							<div style={{width: 60, height: 60, display: "flex", alignItems: "center", justifyContent: "center", border: '0.5px solid', marginRight: 20, borderRadius: 30}}>
								<p style={{color: "#252525", textAlign: "center", fontFamily: "Futura", fontSize: 20}}>
									{el.name}
								</p>
							</div>
						)
					})
				}
				

			</div>
    </div>
	)
}

export default Footer;
import React, { useEffect, useState } from "react";
import Box from '@mui/material/Box';
import Navbar from "src/sections/Navbar";
import Hero from "src/sections/Hero";


import privacy from '../../assets/privacy.txt'
import ReactMarkdown from 'react-markdown'

// import './Landing.css';



const Privacy = () => {
  const [privacyText, setPrivacy] = useState("");

  useEffect(() => {
    fetch(privacy).then((response) => response.text()).then((text) => {
      setPrivacy(text)
    })
  }, [])

  console.log("privacy", privacyText.split("\n"))

  return (
    <Box sx={{ flexGrow: 1, backgroundColor: "white" }}>
      <Navbar />
      <div style={{ backgroundColor: "#003DEA" }}>
        <Hero
          title={"Privacy Policy"}
          subtitle={false} />
      </div>

      <div style={{ paddingLeft: "14vh", paddingRight: "14vh", paddingTop: 42, paddingBottom: 64 }}>
        {privacyText.split("\n").map((item, i) => {
          return (
            <p style={{ color: "black", fontFamily: "Futura", fontSize: 16, paddingTop: 6 }}>
              {item}
            </p>
          )
        })}

      </div>

    </Box>
  )
}

export default Privacy;
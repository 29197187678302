import React, { useRef } from "react"
import Hero from "../../sections/Hero";
import Navbar from "../../sections/Navbar";
import CategorySection from "../../sections/CategorySection";
import Why from "../../sections/Why";
import Footer from "../../sections/Footer";
import InfoSection from "../../sections/InfoSection";
import Box from '@mui/material/Box';

import './Landing.css';

const scrollOptions = { behavior: 'smooth', block: 'start' }

const Landing = () => {
	const myRef = useRef(null);
	const myRef2 = useRef(null);
	const myRef3 = useRef(null);

	const scroll = (i) => {
		if (i === 1)
			myRef.current.scrollIntoView(scrollOptions)
		else if (i === 2)
			myRef2.current.scrollIntoView(scrollOptions)
		else if (i === 3)
			myRef3.current.scrollIntoView(scrollOptions)
	}

	return (
		<Box sx={{ flexGrow: 1, backgroundColor: "white" }}>
			<Navbar />
			<div style={{ backgroundColor: "#003DEA" }}>
				<Hero
					subtitle={true}
					executeScroll={(i) => scroll(i)} />
			</div>
			<div ref={myRef}>
				<Why />
			</div>
			<CategorySection />
			<InfoSection />
			<Footer />
		</Box>
	)
}
export default Landing;
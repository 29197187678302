import React from "react";
import CTAButton from "../../components/CTAButton";
import './Who.css';


const Why = ()=>{
  return( 
    <div className="hero" style={{alignItems: "flex-start", display:'flex', paddingBottom: 20, flexDirection: "column"}}>
      <p style={{color: "#003DEA", fontFamily: "Futura", fontSize: 24}}>
        Why?
      </p>
      <p 
        className="why-text" 
        style={{fontFamily: "Futura", paddingTop: 18, color: "#252525"}}>
        mobile apps have changed our daily life more that anything ever before, a big chance to change the life of millions for the better one app at a time. 
      </p>
      <div style={{paddingTop: 42}}>
        <CTAButton
          text="Join the journey"
          onClick={()=> {
            window.location.href = "mailto:hello@manav.studio"
          }}
          color="black"
        />
      </div>
    </div>
  )
}

export default Why;
import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';
import 'firebase/compat/auth';
import 'firebase/compat/storage';
import 'firebase/compat/database';

// Replace this with your own config details
var config = {
  apiKey: "AIzaSyDTCeeIZCFssbL__YHtmCQlz1cJshWdNkk",
  authDomain: "project-unite-361.firebaseapp.com",
  projectId: "project-unite-361",
  databaseURL: "https://project-unite-361.firebaseio.com",
  storageBucket: "project-unite-361.appspot.com",
  messagingSenderId: "1035152439025",
  appId: "1:1035152439025:web:abc5db4935371e0e35db50",
  measurementId: "G-ET6QCDD317"
};

firebase.initializeApp(config);
firebase.firestore().settings({ timestampsInSnapshots: true });
const storage = firebase.storage()
const auth = firebase.auth()

export {
  storage, 
  auth,
  firebase as default
} 
import React from "react";
// import './Who.css';


const CategorySection = ()=>{
  return( 
    <div className="hero" style={{alignItems: "center", display:'flex', flexDirection: "column"}}>

      {/* <img src="img1.png" style={{width: "50vw", height: "50vw", position: "absolute", zIndex: 1}}/> */}

      <p style={{color: "#003DEA", textAlign: "center", fontFamily: "Futura", fontSize: 44}}>
        Health & Fitness
      </p>
      <p style={{color: "#003DEA", textAlign: "center", fontFamily: "Futura", paddingTop: 40, fontSize: 44}}>
        Utilities
      </p>
      <p style={{color: "#003DEA", textAlign: "center", fontFamily: "Futura", paddingTop: 40, fontSize: 44}}>
        Entertainment
      </p>
      <p style={{color: "#003DEA", textAlign: "center", fontFamily: "Futura", paddingTop: 40, fontSize: 44}}>
        Life Applications
      </p>
    </div>
  )
}

export default CategorySection;
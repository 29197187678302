import React from "react";
import {Button} from '@mui/material';

const CTAButton = ({text, color, onClick })=>{
  return(
    <Button onClick={onClick} variant="outlined" style={{padding: 8, borderRadius: 0, paddingLeft: 18, paddingRight: 18, borderWidth: 1, display: "flex", borderColor: color}}>
      <p style={{fontSize: 16, fontFamily: "Futura", color: color}}>{text}</p>
      <img src={color === "black" ? "arrow-action-black.png" : "arrow-action.png"} style={{width: 24, height: 24, marginLeft: 4}}/>  
    </Button>
  )
}

export default CTAButton;